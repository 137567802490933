<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card class="pa-5">
            <div class="text-h4 text-center">Add Investment</div>
            <v-form ref="form"
            >
              <v-container>
                <v-row class="justify-center">
                  <v-col cols="12" lg="6">
                    <v-alert type="error" v-if="error">{{ error }}</v-alert>
                    <v-text-field
                        v-model="formData.ref_name"
                        label="Ref Name"
                        type="text"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col class="d-flex"
                         cols="12"
                         lg="3">
                    <v-select
                        :items="projectOptions"
                        v-model="formData.project"
                        label="Projects"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-text-field
                        v-model="formData.amount"
                        label="Amount"
                        type="number"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="12" lg="3">
                    <v-select v-model="formData.currency" :items="curencey" label="Currency Type"></v-select>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="formData.date"
                            label="Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="formData.date"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="12" lg="6">
                    <v-text-field
                        v-model="formData.ref_person"
                        label="Ref Person"
                        type="text"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="12" lg="6">
                    <v-textarea
                        rows="2"
                        v-model="formData.description"
                        label="Description"
                        type="text"
                        required
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
              <v-row class="justify-center">
                <v-col cols="12" lg="6" class="text-center">
                  <v-btn color="primary" class="px-10" @click="createInvestment()" :disabled="loading">Add</v-btn>
                  <br>
                  <v-progress-circular
                      indeterminate
                      color="primary"
                      class="mt-5"
                      v-if="loading"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {create_investment} from "@/apis/investment";
import {get_all_projects} from "@api/project";

export default {
  data() {
    return {
      formData: {
        ref_name: "",
        project: "",
        amount: "",
        currency: "",
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        ref_person: "",
        description: ""
      },
      curencey: [
        {
          text: 'PKR - Pakistan Rupees',
          value: 'pkr'
        },
        {
          text: "SAR - Saudi Riyal",
          value: 'riyal'
        },
        {
          text: "OMR - Omani Riyal",
          value: 'omr'
        },
        {
          text: "Pound",
          value: 'pound'
        }
      ],
      loading: false,
      error: '',
      projectOptions: [],
      getLoading:false,
    }
  },
  mounted() {
    this.projectList();
  },
  methods: {
    projectList() {
      this.getLoading= true
      this.error=''
      get_all_projects()
      .then(resp => {
          this.getLoading= false
          if(resp.status == 'Success'){
            this.setProjectOptions(resp.data)
          }else{
            this.error=resp.errors;
          }
        }
      )
    },
    setProjectOptions(data) {
      let list = [];
      data.forEach(p => {
        var obj = {}
        obj['text'] = p.name;
        obj['value'] = p.id;
        list.push(obj);
      });
      this.projectOptions = list
    },
    createInvestment() {
      this.loading = true;
      this.error = '';
      create_investment(this.formData).then(
          resp => {
            this.loading = false
            if (resp.status == 'Success') {
              this.$toastr.s(resp.message);
              this.$router.push('/investments')
              this.$refs.form.reset();
            } else {
              this.error = resp.errors;
            }
          }
      )
    }
  },
}
</script>

<style>

</style>